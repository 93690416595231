"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EventTarget {
    _nameHandlersMap = new Map();
    addEventListener(type, callback) {
        let handlers = this._nameHandlersMap.get(type);
        if (handlers === undefined) {
            handlers = [];
            this._nameHandlersMap.set(type, handlers);
        }
        handlers.push(callback);
    }
    removeEventListener(type, callback) {
        const handlers = this._nameHandlersMap.get(type);
        if (handlers === undefined) {
            return;
        }
        for (let i = 0, l = handlers.length; i < l; i++) {
            if (handlers[i] === callback) {
                handlers.splice(i, 1);
                return;
            }
        }
    }
    dispatchEvent(event) {
        const handlers = this._nameHandlersMap.get(event.type);
        if (handlers === undefined) {
            return true;
        }
        const handlersCopy = handlers.slice();
        for (let i = 0, l = handlersCopy.length; i < l; i++) {
            handlersCopy[i].call(this, event);
        }
        return !event.defaultPrevented;
    }
}
exports.default = EventTarget;
