"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderDebugLayer = exports.renderFrame = void 0;
const canvas_helpers_1 = require("./canvas-helpers");
function renderFrame(manager, canvas, data, paints) {
    canvas.clear(manager.kit.WHITE);
    // Draw selected texts.
    if (data.selectionRegions?.regions) {
        for (const rect of data.selectionRegions.regions) {
            const [x0, y0, x1, y1] = rect;
            canvas.drawRect([x0, y0 + data.offsetY, x1, y1 + data.offsetY], paints.selection);
        }
    }
    // Draw the paragraph.
    canvas.drawParagraph(data.paragraph, 0, data.offsetY);
    // Draw the caret.
    if (data.selectionRegions?.caret) {
        const [x, top, bottom] = data.selectionRegions.caret;
        canvas.drawLine(x, data.offsetY + top, x, data.offsetY + bottom, paints.caret);
    }
    const canvasHeight = manager.height;
    const paragraphHeight = data.paragraph.getHeight();
    if (paragraphHeight > canvasHeight) {
        const scrollBarBaseY = canvasHeight * (-data.offsetY / paragraphHeight);
        const scrollBarWidth = 8;
        const scrollBarHeight = canvasHeight * (canvasHeight / paragraphHeight);
        canvas.drawRect([
            manager.width - scrollBarWidth,
            scrollBarBaseY,
            manager.width,
            scrollBarBaseY + scrollBarHeight,
        ], paints.scrollBar);
    }
}
exports.renderFrame = renderFrame;
function renderDebugLayer(manager, canvas, data, paints) {
    // Draw glyph borders.
    if (data.shapedLines) {
        canvas_helpers_1.drawShapedLines(canvas, data.shapedLines, manager.width, paints.line, paints.topAndBottom);
    }
    // Indicate the cursor position.
    if (data.lastMousePosition) {
        canvas.drawCircle(...data.lastMousePosition, 4, paints.cursor);
    }
}
exports.renderDebugLayer = renderDebugLayer;
