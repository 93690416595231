"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawShapedLines = void 0;
function drawShapedLines(canvas, shapedLines, width, paintForLine, paintForTopAndBottom) {
    for (const line of shapedLines) {
        // canvas.drawRect([0, line.top, width, line.bottom], paintForRun);
        canvas.drawLine(0, line.baseline, width, line.baseline, paintForLine);
        canvas.drawLine(0, line.top, width, line.top, paintForTopAndBottom);
        canvas.drawLine(0, line.bottom, width, line.bottom, paintForTopAndBottom);
        for (const run of line.runs) {
            for (let i = 0; i < run.positions.length; i += 2) {
                const x = run.positions[i];
                canvas.drawLine(x, line.top, x, line.bottom, paintForLine);
            }
        }
    }
}
exports.drawShapedLines = drawShapedLines;
