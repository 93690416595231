"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CanvasManager {
    _scale = window.devicePixelRatio;
    _kit;
    _element;
    _size;
    _surface;
    initializeElement() {
        this._element.width = this._size[0] * this._scale;
        this._element.height = this._size[1] * this._scale;
        this._element.style.width = `${this._size[0]}px`;
        this._element.style.height = `${this._size[1]}px;`;
    }
    initializeCanvas() {
        const canvas = this._surface.getCanvas();
        canvas.scale(this._scale, this._scale);
    }
    constructor(kit, selectorOrElement, width = 800, height = 400) {
        this._kit = kit;
        if (typeof selectorOrElement === "string") {
            const element = document.querySelector(selectorOrElement);
            if (element instanceof HTMLCanvasElement) {
                this._element = element;
            }
            else {
                throw new TypeError("expect a canvas element");
            }
        }
        else {
            this._element = selectorOrElement;
        }
        this._size = [width, height];
        this.initializeElement();
        const surface = kit.MakeCanvasSurface(this._element);
        if (surface === null) {
            throw new Error("cannot make the canvas surface");
        }
        this._surface = surface;
        this.initializeCanvas();
    }
    requestAnimationFrame(callback) {
        this._surface.requestAnimationFrame(callback);
    }
    get width() {
        return this._size[0];
    }
    get height() {
        return this._size[1];
    }
    get kit() {
        return this._kit;
    }
    addEventListener(type, listener, options) {
        this._element.addEventListener(type, listener, options);
    }
}
exports.default = CanvasManager;
