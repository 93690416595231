"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFonts = exports.preloadFonts = exports.loadArrayBuffer = void 0;
const fonts_1 = require("../shared/fonts");
const urlArrayBufferMap = new Map();
async function loadArrayBuffer(url) {
    const bufferOrPromise = urlArrayBufferMap.get(url);
    if (bufferOrPromise === undefined) {
        const promise = fetch(url)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
            urlArrayBufferMap.set(url, arrayBuffer);
            return arrayBuffer;
        });
        urlArrayBufferMap.set(url, promise);
        return promise;
    }
    else if (bufferOrPromise instanceof ArrayBuffer) {
        return Promise.resolve(bufferOrPromise);
    }
    else {
        return bufferOrPromise;
    }
}
exports.loadArrayBuffer = loadArrayBuffer;
function preloadFonts() {
    for (const font of fonts_1.fonts) {
        loadArrayBuffer(font.runtimeFileName);
    }
}
exports.preloadFonts = preloadFonts;
async function loadFonts(kit) {
    const loadedFonts = await Promise.all(fonts_1.fonts.map((font) => loadArrayBuffer(font.runtimeFileName)));
    const fontMgr = kit.FontMgr.FromData(...loadedFonts);
    if (fontMgr === null) {
        throw new Error("cannot create FontMgr");
    }
    return fontMgr;
}
exports.loadFonts = loadFonts;
