"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sampleText = void 0;
exports.sampleText = [
    `Killing Commendatore (Japanese: 騎士団長殺し, Hepburn: Kishidanchō-goroshi) is a 2017 novel written by Japanese writer Haruki Murakami. `,
    `It was first published in two volumes–The Idea Made Visible (顕れるイデア編, Arawareru idea hen) and `,
    `The Shifting Metaphor (遷ろうメタファー編, Utsurou metafā hen), `,
    `respectively–by Shinchosha in Japan on 24 February 2017. `,
    `An English translation by Philip Gabriel and Ted Goossen was released as a single, `,
    `704-page volume on 9 October 2018 by Alfred A. Knopf in the US and by Harvill Secker in the UK.`,
].join("");
